<template>
  <div id="blogCategory">
      <div style=" margin: 20px 50px; padding: 10px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);" >
        <div style="width: 100%; text-align:right; padding: 10px;">
          <el-button size="mini" @click="select()" style="margin: 0px 20px"
            >查询</el-button
          >
          <el-button
            size="mini"
            @click="addShow = true"
            style="margin: 0px 20px"
            >添加</el-button
          >
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="分类名称" />
          <el-table-column
            :formatter="formStatus"
            prop="status"
            label="状态"
          />
          <el-table-column label="操作" width="150px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="edit(scope.$index, scope.row)"
                v-if="scope.row.status != 1"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="deletes(scope.$index, scope.row)"
                v-if="scope.row.status != 1"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.pageNum"
        @current-change="handleCurrentChange"
        style="padding: 2px 5px 20px 5px"
      />
    <el-dialog title="编辑文章分类" :visible.sync="editShow" width="30%">
      <div style="text-align: left;">
        <div>分类名称：</div>
        <el-input
          v-model="categoryName"
          placeholder="请输入分类名称"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="save(false)">取 消</el-button>
        <el-button type="primary" @click="save(true)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加文章分类" :visible.sync="addShow" width="30%">
      <div style="text-align: left;">
        <div>分类名称：</div>
        <el-input
          v-model="categoryName"
          placeholder="请输入分类名称"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add(false)">取 消</el-button>
        <el-button type="primary" @click="add(true)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from "moment";
  import utils from "@/config/config.js";
  export default {
    name: "blogCategory",
    props: {},
    data() {
      return {
        tableData: [],
        parameter: {},
        page: {
          total: 1,
          pageSize: 20,
          pageNum: 1,
        },
        isAdmin: null,
        addShow: false,
        categoryName: "",
        deleteShow: false,
        editShow: false,
        id: null,
      };
    },
    mounted() {
      this.select();
    },
    methods: {
      formStatus(row) {
        switch (row.status) {
          case 0:
            return "正常";
          case 1:
            return "已删除";
        }
      },
      handleCurrentChange(val) {
        this.parameter.pageNum = val;
        this.selectMyBlogList();
      },
      /* 日期处理 */
      dateFormat: function(row, column) {
        const date = row[column.property];
        if (date === undefined) {
          return "";
        }
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      },
      routerPath(pa) {
        if (this.$route.path !== pa) {
          this.$router.push({ path: pa });
        }
      },
      // 添加
      add(isAdd) {
        if (isAdd) {
          const _this = this;
          $.ajax({
            type: "POST",
            url: utils.url + "/api/blogCategory/blogCategoryAdd",
            data: _this.categoryName,
            contentType: utils.contentType,
            jsonp: utils.jsonp,
            success: function(data, state, ret) {
              if (ret.status === 200) {
                if (data.code === 200) {
                  console.log(data);
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  _this.select();
                } else {
                  _this.$message.error(data.msg);
                }
              } else {
                _this.routerPath("/login");
              }
            },
            error: function(ret) {
              if (ret.status === 403 || ret.status === 401) {
                _this.routerPath("/login");
              }
            },
            beforeSend: utils.beforeSend,
            xhrFields: utils.xhrFields,
          });
        }
        this.categoryName = "";
        this.addShow = false;
      },
      // 编辑
      edit(index, row) {
        const _this = this;
        $.ajax({
          type: "GET",
          url:
            utils.url +
            "/api/blogCategory/blogCategoryIdSelect/" + row.id,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.id = data.data.id;
                _this.categoryName = data.data.name;
                _this.editShow = true;
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      save(editShow) {
        if (editShow) {
          const _this = this;
          this.parameter = {
            name: _this.categoryName,
            id: _this.id,
          };
          $.ajax({
            type: "POST",
            url: utils.url + "/api/blogCategory/updateBlogCategoryName",
            data: JSON.stringify(_this.parameter),
            contentType: utils.contentType,
            jsonp: utils.jsonp,
            success: function(data, state, ret) {
              if (ret.status === 200) {
                if (data.code === 200) {
                  _this.editShow = false;
                  _this.select();
                } else {
                  _this.$message.error(data.msg);
                }
              } else {
                _this.routerPath("/login");
              }
            },
            error: function(ret) {
              if (ret.status === 403 || ret.status === 401) {
                _this.routerPath("/login");
              }
            },
            beforeSend: utils.beforeSend,
            xhrFields: utils.xhrFields,
          });
        }
        this.categoryId = "";
        this.categoryName = "";
        this.editShow = false;
      },
      // 删除
      deletes(index, row) {
        const _this = this;
        $.ajax({
          type: "POST",
          url:
            utils.url +
            "/api/blogCategory/blogCategoryDelete/" +
            row.id,
          data: "",
          contentType: utils.contentType,
          jsonp: utils.jsonp,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.select();
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      //查询
      select() {
        this.parameter = {}
        this.parameter.pageSize = this.page.pageSize;
        this.parameter.pageNo = this.page.pageNum
        const _this = this;
        $.ajax({
          type: "GET",
          url: utils.url + "/api/blogCategory/blogCategorySelect",
          data: _this.parameter,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.tableData = data.data.records;
                _this.page.total = data.data.total;
              } else {
                _this.$message.error(data.msg);
              }
            } else {
              _this.routerPath("/login");
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.routerPath("/login");
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
    },
  };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #blogCategory {
    width: 100%;
  }
</style>
